import * as React from 'react';

import { AppBar, CssBaseline, Toolbar, IconButton, Grid } from '@mui/material';

import { Link } from 'react-router-dom';

import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

export default function BottomAppBar() {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, backgroundColor: "#000" , height: "75px"}}>
        <Toolbar>
            <Grid container justifyContent="space-evenly">
                <IconButton
                    size="large"
                    aria-label="user acount and other pages/settings"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    component={Link} 
                    to="/"
                >
                    <svg width={0} height={0}>
                        <linearGradient id="gradientColors" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset='0%' stopColor="#9BF1F9" />
                            <stop offset='100%' stopColor="#1885E9" />
                        </linearGradient>
                    </svg>
                    <HouseOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: "30px" }} />
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="user acount and other pages/settings"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    component={Link} 
                    to="/rateverses"
                >
                    <svg width={0} height={0}>
                        <linearGradient id="gradientColors" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset='0%' stopColor="#9BF1F9" />
                            <stop offset='100%' stopColor="#1885E9" />
                        </linearGradient>
                    </svg>
                    <FavoriteBorderOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: "30px" }} />
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="user acount and other pages/settings"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    component={Link} 
                    to="/categories"
                >
                    <svg width={0} height={0}>
                        <linearGradient id="gradientColors" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset='0%' stopColor="#9BF1F9" />
                            <stop offset='100%' stopColor="#1885E9" />
                        </linearGradient>
                    </svg>
                    <GroupWorkOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: "30px" }} />
                </IconButton>
                {/* <IconButton
                    size="large"
                    aria-label="user acount and other pages/settings"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    component={Link} 
                    to="/courses"
                >
                    <svg width={0} height={0}>
                        <linearGradient id="gradientColors" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset='0%' stopColor="#9BF1F9" />
                            <stop offset='100%' stopColor="#1885E9" />
                        </linearGradient>
                    </svg>
                    <ChecklistRtlOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: "30px" }} />
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="user acount and other pages/settings"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    component={Link} 
                    to="/biblestats"
                >
                    <svg width={0} height={0}>
                        <linearGradient id="gradientColors" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset='0%' stopColor="#9BF1F9" />
                            <stop offset='100%' stopColor="#1885E9" />
                        </linearGradient>
                    </svg>
                    <QueryStatsOutlinedIcon sx={{ fill: "url(#gradientColors)", fontSize: "30px" }} />
                </IconButton> */}
            </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}