import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Navbar from '../layout/Navbar';
import Home from '../pages/Home';
import About from '../pages/About';
import RateVerses from '../pages/RateVerses';
import Recommendations from '../pages/Recommendations';
import Terms_and_conditions from '../pages/Terms_and_conditions';
import PrivacyPolicy from '../pages/Privacy_policy';
import Footer from '../layout/Footer';
import Categories from '../pages/Categories';
import ViewCategory from '../pages/ViewCategory';
import ViewVerse from '../pages/ViewVerse';
import MyVerses from '../pages/MyVerses';
import UserSettngs from '../pages/Settings';

import LoginPage from '../pages/Login';
import RegisterPage from '../pages/Register';

import BottomAppBar from '../layout/BottomNavbar';
import { Box } from '@mui/material';

const Routers = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/rateverses" element={<RateVerses />} />
                <Route path="/about" element={<About />} />
                <Route path="/recommendations" element={<Recommendations />} />
                <Route path="/termsandconditions" element={<Terms_and_conditions />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/viewcategory/:id" element={<ViewCategory />} />
                <Route path="/viewverse/:category/:id" element={<ViewVerse />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/myverses/:userID" element={<MyVerses />} />
                <Route path="/settings/:userID" element={<UserSettngs />} />
            </Routes>
            <Box sx={{display: { xs: 'flex', md: 'none' }}}>
                <BottomAppBar/>
            </Box>
        </Router>
    );
};

export default Routers;
