import { Grid, Typography, Box, IconButton, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from "@mui/material";

import { useState, useEffect } from "react";

import { getData } from "../../services/api";

import { useParams, Link, useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CardComponent from '../../components/CardComponent'
import BasicModal from "../../components/Modal";
import LikeButton from "../../components/LikeButton";
import ShareIconButton from "../../components/ShareIcon";
import BookmarkButton from "../../components/BookmarkButton";

import styles from './viewvers.module.css'


const ViewVerse = () => {
    const { category, id } = useParams();
    const [responseData, setResponseData] = useState(null);
    const [verseID, setVerseID] = useState(id);
    const navigate = useNavigate();
    const { isLoggedIn, userData } = useAuth();


    const handleGet = async () => {
        try {
        const response = await getData(`viewFullVerseDetails&verseID=${id}`);
            setResponseData(response);
        } catch (error) {
            console.error('Error in handleGet:', error);
        }
    };

    useEffect(() => {
        handleGet(verseID);
    }, [verseID]);

    useEffect(() => {
        setVerseID(id);
    }, [id]);


    const getCategoryParts = (str) => {
        const tildeIndex = str.indexOf('~');
        if (tildeIndex !== -1) {
            return {
                categoryLink: `/viewcategory/${str.substring(tildeIndex + 1).trim()}`,
                labelText: str.substring(0, tildeIndex).trim()
            };
        }
        return {
            categoryLink: '',
            labelText: str
        };
    };

    let backHref = (category === "0" && isLoggedIn) ? `/myverses/${userData.userID}`: `/viewcategory/${category}`;

    const handleReferenceClick = (verseRefIDs) => {
        setVerseID(verseRefIDs);
        navigate(`/viewverse/${category}/${verseRefIDs}`);
    };

    const cardContent = () => (
        <>
            <Box>
                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                    {responseData.book} {responseData.chapter}: {responseData.verse} ({responseData.accronymn})
                </Typography>
                <Typography sx={{ mb: 1.5 }} variant="h5" color="text.primary" className={styles.mainText}>
                    {responseData.verse_text}
                </Typography>
            </Box>
        </>
    );

    const cardActions = () => {
        let shareURL = `viewverse/${category}/${id}`;
        return(
            <>
                <Box sx={{ textAlign: "left", width: "50%" }}>
                    <BasicModal buttonText="Full Chapter" chapterID={responseData.chapter} bookID={responseData.bookID} verseID={responseData.verse} />
                </Box>
                <Box sx={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center" }}>
                    {/* <LikeButton likes={responseData.likes} id={responseData.id} disabled={true} />  */}
                    <BookmarkButton id={id} />
                    <IconButton aria-label="share" size="small" sx={{ color: "blue !important" }}>
                        <ShareIconButton
                            verseID={responseData.id}
                            verseBook={responseData.book}
                            verseChapter={responseData.chapter}
                            verseNumber={responseData.verse}
                            verseAccronymn={responseData.accronymn}
                            url={shareURL}
                            text="See what the Lord says."
                        />
                    </IconButton>
                </Box>
            </>
        );
    }

    return(
        <Grid container direction={{ xs: "row", md: "column" }} alignItems="center" className={styles.mainGrid}>
            <Grid item sx={{ width: { xs: "100%", md: "37%" }, display:"flex", flexDirection:"row"}}>
                <Box sx={{width: "15%", display:"flex", alignItems: 'center'}}>
                    <IconButton component={Link} to={backHref} >
                        <ChevronLeftOutlinedIcon sx={{ fontSize: "40px"}}/>
                    </IconButton>
                </Box>
                <Box sx={{width: "85%", display:"flex", alignItems: 'center'}}>
                    {responseData && (
                        <Typography variant='h5' component="h2">{responseData.book} {responseData.chapter}:{responseData.verse}</Typography>
                    )}
                </Box>
            </Grid>
            <Grid item>
                {responseData && (
                    <CardComponent 
                        cardContent={cardContent}
                        cardActions={cardActions}
                    />
                )}
            </Grid>
            <Grid item sx={{ width: { xs: "100%", md: "30%" }, padding: "10px 20px" }}>
                <Grid container direction="column" alignItems="start" sx={{ width: "100%" }}>
                    <Grid item sx={{ width: "100%", textAlign: "left"}} mb={2}>
                        <Typography variant="h5" component="h2">Categories</Typography>
                        {responseData && (responseData.category && responseData.category !== null
                                ? (responseData.category.includes(',') 
                                    ? responseData.category.split(',') 
                                    : [responseData.category])
                                : ["N/A"]
                            ).map((category, index) => {
                            const { categoryLink, labelText } = getCategoryParts(category);
                            return(
                                <Link to={categoryLink}>
                                    <Chip label={labelText} variant="outlined" sx={{marginRight: "5px"}} key={index} />
                                </Link>
                            );
                        })}
                    </Grid>
                    <Grid item sx={{ width: "100%", textAlign: "left" }} mb={2}>
                        <Typography variant="h5" component="h2">Group Of Books</Typography>
                        {responseData && (
                            <Chip label={responseData.bookGroup} variant="outlined" sx={{ backgroundColor: `${responseData.bookGroupColor}`}}/>
                        )}
                    </Grid>
                    <Grid item sx={{ width: "100%", textAlign: "left" }}>
                        <Typography variant="h5" component="h2">References</Typography>
                        <List>
                            {responseData && responseData.references && Object.keys(responseData.references).length > 0 ? (
                                Object.keys(responseData.references).map((key, index) => {
                                    const reference = responseData.references[key];
                                    return (
                                        <div key={index}>
                                            <ListItem
                                                sx={{ width: "100%", height: "50px" }}
                                                disablePadding
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="comments">
                                                        <ChevronRightIcon sx={{ color: "black" }} />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemButton onClick={() => handleReferenceClick(reference.verseRefIDs)}>
                                                    <ListItemText primary={`${reference.bookName} ${reference.chapter}:${reference.verses}`} />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    );
                                })
                            ) : (
                                <ListItem sx={{ width: "100%", height: "50px" }} disablePadding>
                                    <ListItemText primary="N/A" />
                                </ListItem>
                            )}
                        </List>
                        
                    </Grid>
                </Grid>
            </Grid>
           
            
        </Grid>
    );
}

export default ViewVerse;