import { RWebShare } from "react-web-share";

import ShareIcon from '@mui/icons-material/Share';
import { Grid } from "@mui/material";

let baseURL = process.env.REACT_APP_BASE_URL;
 
function ShareIconButton(props) {
    let shareUrl = (props.url !== "") ? `${baseURL}${props.url}` : `${baseURL}`;
    let shareText = (props.text !== "") ? props.text : "Verse Of The Day";

    return (
        <Grid>
            <RWebShare
                data={{
                    text: `${props.verseBook} ${props.verseChapter}: ${props.verseNumber} (${props.verseAccronymn})`,
                    url: shareUrl,
                    title: shareText,
                }}
            >
                <ShareIcon sx={{color: "#000"}}/>
            </RWebShare>
        </Grid>
    );
}

export default ShareIconButton;