import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import { useState } from 'react';

const CustomSelect = ({ id, name, label, value, onChange, onBlur, error, helperText, sx }) => {
  const [category, setCategory] = useState('');

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={error} sx={sx}>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
            labelId={`${id}-label`}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            label={label}
        >
          <MenuItem value="recommendation">Recommendation</MenuItem>
          <MenuItem value="feature">Feature</MenuItem>
          <MenuItem value="bug">Bug</MenuItem>
          <MenuItem value="feedback">Feedback</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default CustomSelect;