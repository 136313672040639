import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';

import styles from "./card.module.css"

const CardComponent = (props) => {
    return(
        <Card className={styles.verseCard}>
            <CardContent className={styles.cardContent}>
                {props.cardContent()}
            </CardContent>
            <CardActions className={styles.cardActions}>
                {props.cardActions()}
            </CardActions>
        </Card>
    );
}

export default CardComponent;
