import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { postData } from '../../services/api';
import { useAuth } from '../../context/AuthContext';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { IconButton, Grid, Typography } from '@mui/material';

import CustomButton from '../Custom_Button';

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if ( event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')){
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const { isLoggedIn, userData } = useAuth();

  const userPages = ['About', 'Recommendations'];
  if (isLoggedIn) {
    userPages.push('Settings', 'My Verses');
  }

  const handleLogout = async () => {
    let data = {
      method: "logoutUser"
    };
    
    try {
      const response = await postData(data);
  
      if (response.status === "success") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        alert("error");
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const list = (anchor) => (
    <Box
        sx={{ 
            width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
    > 
      <List>
      {userPages.map((page, index) => {
        let link = page.toLowerCase() !== 'home' ? `/${page.toLowerCase().replace(/\s+/g, '')}` : '/';
        if(link === "/myverses" || link === "/settings"){
          link = link + `/${userData.userID}`
        }
       
        return (
          <ListItem key={page} disablePadding>
            <ListItemButton>
                <Button key={index} component={Link} to={link} color="inherit" >
                    {page}
                </Button>
            </ListItemButton>
          </ListItem>
        );
      })}
      </List>

      {!isLoggedIn && (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: "100%"}}>
            <CustomButton to="/login" text="Login" variant="contained" sx={{marginBottom: "10px"}}/>
            <CustomButton to="/register" text="Register" variant="outlined"/>
        </Grid>
      )}
      
      <List>
        {['Terms And Conditions', 'Privacy Policy', ].map((page, index) => {
            const link = page.toLowerCase() !== 'home' ? `/${page.toLowerCase().replace(/\s+/g, '')}` : '/';
            return (
              <ListItem key={page} disablePadding>
                  <ListItemButton>
                      <Button key={index} component={Link} to={link} color="inherit" >
                          {page}
                      </Button>
                  </ListItemButton>
              </ListItem>
            );
        })}  
        {isLoggedIn && (
          <ListItem  disablePadding>
              <ListItemButton>
                  <Button color="inherit" onClick={handleLogout}>
                    Logout
                  </Button>
              </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
            <IconButton
                size="large"
                aria-label="user acount and other pages/settings"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(anchor, true)}
                color="inherit"
            >
                <svg width={0} height={0}>
                    <linearGradient id="gradientColors" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset='0%' stopColor="#9BF1F9" />
                        <stop offset='100%' stopColor="#1885E9" />
                    </linearGradient>
                </svg>
                <PersonOutlineIcon sx={{ fill: "url(#gradientColors)", fontSize: "35px" }} />
            </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {isLoggedIn && 
              <Grid container direction="column" justifyContent="center" alignItems="center" sx={{marginTop: "10px"}}>
                <Typography variant='h6' component="h2">{userData.username}</Typography>
              </Grid>
            }
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}