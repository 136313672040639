import { Grid, Typography } from "@mui/material";

import styles from "./tandc.module.css"

const Terms_and_conditions = () => {
    return(
        <Grid container className={styles.mainGrid}>
            <Grid item className={styles.textFormatting}>
                <Typography variant="h4">Introduction:</Typography>
                <Typography variant="body1">
                    Welcome to Verse of the Day! These Terms and Conditions govern your use of our web application. 
                    By accessing or using Verse of the Day, you agree to be bound by these terms. If you do not agree to these terms, please do not use our web app.
                </Typography>

                <Typography variant="h4">Use of the App:</Typography>

                <Typography variant="h5">Eligibility:</Typography>
                <Typography variant="body1">
                    You must be at least 13 years old to use Verse of the Day. By using this app, you represent and warrant that you meet this age requirement.
                </Typography>

                <Typography variant="h5">Account:</Typography>
                <Typography variant="body1">
                    To access certain features of the app, you may be required to create an account. 
                    You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. 
                    You agree to notify us immediately of any unauthorized use of your account.
                </Typography>

                <Typography variant="h5">License:</Typography>
                <Typography variant="body1">
                    Verse of the Day grants you a limited, non-exclusive, non-transferable, and revocable license to use the app for personal, non-commercial use only. 
                    You agree not to use the app for any illegal or unauthorized purpose.
                </Typography>

                <Typography variant="h4">Content:</Typography>

                <Typography variant="h5">User-Generated Content:</Typography>
                <Typography variant="body1">
                    You may be able to submit content to Verse of the Day, including but not limited to comments, feedback, and suggestions. 
                    By submitting content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and display your content in connection with the app.
                </Typography>

                <Typography variant="h5">Intellectual Property:</Typography>
                <Typography variant="body1">
                    All content provided on Verse of the Day, including but not limited to text, graphics, logos, and images, 
                    is the property of Verse of the Day or its content suppliers and is protected by intellectual property laws. 
                    You agree not to reproduce, distribute, or create derivative works from any content without our prior written consent.
                </Typography>

                <Typography variant="h4">Prohibited Activities:</Typography>
                <Typography variant="body1">You agree not to engage in any of the following prohibited activities:</Typography>
                <Typography variant="body1">a) Violating any applicable laws or regulations.</Typography>
                <Typography variant="body1">b) Infringing on the intellectual property rights of others.</Typography>
                <Typography variant="body1">c) Uploading or transmitting viruses or other harmful code.</Typography>
                <Typography variant="body1">d) Interfering with the security or integrity of the app.</Typography>

                <Typography variant="h4">Termination:</Typography>
                <Typography variant="body1">
                    We reserve the right to terminate or suspend your access to the app at our discretion, without notice, 
                    for conduct that we believe violates these Terms and Conditions or is harmful to other users of the app, us, or third parties.
                </Typography>

                <Typography variant="h4">Disclaimers:</Typography>
                <Typography variant="body1">
                    Verse of the Day is provided "as is" and "as available" without any warranties of any kind, either express or implied. 
                    We do not warrant that the app will be uninterrupted, error-free, or free of viruses or other harmful components.
                </Typography>

                <Typography variant="h4">Limitation of Liability:</Typography>
                <Typography variant="body1">
                    To the fullest extent permitted by law, Verse of the Day shall not be liable for any indirect, incidental, special, consequential, or punitive damages, 
                    or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from 
                    (a) your use or inability to use the app; 
                    (b) any unauthorized access to or use of our servers and/or any personal information stored therein; 
                    (c) any interruption or cessation of transmission to or from the app; or 
                    (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our app by any third party.
                </Typography>

                <Typography variant="h4">Changes to Terms:</Typography>
                <Typography variant="body1">
                    We reserve the right to modify these Terms and Conditions at any time. 
                    Any changes will be effective immediately upon posting the revised terms on the app. 
                    Your continued use of the app following the posting of changes constitutes your acceptance of such changes.
                </Typography>

                <Typography variant="h4">Contact Us:</Typography>
                <Typography variant="body1">
                    If you have any questions or concerns about these Terms and Conditions, please contact us at info@verseoftheday.com.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Terms_and_conditions