import { Grid, Button, List, ListItem, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { useAuth } from "../../context/AuthContext";
import styles from "./usersettings.module.css"

import { postData } from "../../services/api";

import { useState } from "react";

const UserSettngs = () => {
    const { isLoggedIn, userData } = useAuth();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            let data = {
                method: "deleteUserAccount",
                userID: userData.userID
            }
          
            const response = await postData(data);
            if (response.status === "success") {   
                alert("Account Deleted.");
                sessionStorage.clear();

                window.location.href = "/";
            } else {
                console.error('Error Deleting account: API response not "success"');
            }
        } catch (error) {
            console.error('Error Deleting Account:', error);
        }
    }

    return(
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.mainGrid}>
            <Grid item md={12} xs={12}>
                <Typography variant="h5" component="h2">Settings</Typography>
            </Grid>
            <Grid item md={12} xs={12} className={styles.settings}>
                <List>
                    <ListItem>
                        <Button onClick={handleClickOpen} sx={{ color: "red"}}>
                            Delete Account
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Are You Sure?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Once you click yes at the bottom your account and all information associated with it will be removed from the system. This action cannot be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleDelete} autoFocus sx={{ color: "red"}}>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/* <Button onClick={handleClick}>Delete Account</Button> */}
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
}

export default UserSettngs;