/* =====[STYLES]===== */
import styles from "./navbar.module.css";
/* ================= */

/* =====[REACT]===== */
import {AppBar, Box, Toolbar, Button, Container, Typography, Menu, MenuItem} from '@mui/material';
import { Link } from "react-router-dom";
import { useState } from "react";
/* ================= */

import SwipeableTemporaryDrawer from "../../components/NavDrawer";
import SwipeableDrawerDesktop from "../../components/NavDrawerDesktop";

const pages = ["Home","Rate Verses", "About", "Recommendations"];
// const userPages = ["Login","Register"]

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" className={styles.appBar}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Link to="/" className={styles.navLinks}>
                            <Typography
                                variant="h5"
                                noWrap
                                component="h2"
                                sx={{
                                    mr: 4,
                                    display: { xs: 'flex', md: 'none' },
                                    flexGrow: 1,
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }}
                            >
                                Glimpse Of The Gospel
                            </Typography>
                        </Link>
                        
                        
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}} justifyContent="end">
                            <SwipeableTemporaryDrawer />
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} className={styles.menuItemsLeft}>
                            <Link to="/" className={styles.navLinks}>
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="h2"
                                    sx={{
                                        mr: 4,
                                        display: { md: 'block' },
                                        flexGrow: 1,
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Glimpse Of The Gospel
                                </Typography>
                            </Link>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: "flex" }}} justifyContent="end">
                            <SwipeableDrawerDesktop />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}

export default Navbar;