import { Grid, Typography } from "@mui/material";

import styles from "./recommendations.module.css"

import CustomForm from "../../components/UI_Elements/Form";

const Recommendations = () => {
      return (
        <Grid container direction="row" justifyContent="center" alignItems="center" p={2} className={styles.mainGrid}>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <Typography variant="h4">Recommendations</Typography>
                </Grid>
                <Grid item md={12} xs={12} >
                    <Typography variant="body1">
                        Please leave us any recommendations or feedback you have by filling the form below.
                        <br />
                        We aim to read through everyone's messages and implement what the community wants.
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <CustomForm />
                </Grid>
            </Grid>
            
        </Grid>
      );
}

export default Recommendations;