import { Grid, Typography, Button, TextField } from "@mui/material"
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from "./login.module.css"

import Logo from "../../assets/images/logo_new.png"

import { Link } from "react-router-dom";

import { postData } from "../../services/api";

const validationSchema = yup.object({
    username: yup
      .string('Enter your username')
      .required('Username is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  });

const LoginPage = () => {
    const formik = useFormik({
        initialValues: {
          username: '',
          password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let data = {
                method: "loginUser",
                data: values,
            }
            try {
                const response = await postData(data);
                if (response.status === "success") {
                    sessionStorage.setItem("loggedIn", true);
                    sessionStorage.setItem("userData", JSON.stringify(response.data));

                    window.location.href = "/";
                } else {
                    alert("error");
                }
            } catch (error) {
                console.error('Error registering user:', error);
            }
        },
      });
    
      return (
        <Grid direction="row" justifyContent="center" alignItems="center" className={styles.mainGrid}>
            <Grid item>
                <img src={Logo} alt="logo" className={styles.logo}/>
            </Grid>
            <Grid item>
                <Typography variant="h5" component="h2">Login To Your Account</Typography>
            </Grid>
            <Grid item p={{xs:5}}>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="username"
                        name="username"
                        label="Username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                        className={styles.textFields}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        className={styles.textFields}
                    />

                    <Button
                        fullWidth type="submit"
                        variant="contained"
                        className={styles.button_filled}
                    >
                        Login
                    </Button>
                </form>
            </Grid>
            <Grid item>
                <Typography variant="body1" >
                    Don't have an account?&nbsp;
                    <Link className={styles.linkText} to="/register">Create One</Link>, 
                    to enjoy the fulle experience.
                </Typography>
            </Grid>
        </Grid>
      );
}

export default LoginPage;