import { Typography, Grid, IconButton, Avatar, Box } from '@mui/material';

import { useParams, Link } from "react-router-dom";

import { getData } from '../../services/api';

import { useState, useEffect } from 'react';

import styles from './viewcategory.module.css'

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

import anger from '../../assets/icons/anger.png';
import anxiety from '../../assets/icons/anxiety.png';
import cooperation from '../../assets/icons/cooperation.png';
import finger from '../../assets/icons/finger.png';
import love from '../../assets/icons/love.png';
import pray from '../../assets/icons/pray.png';
import prayer from '../../assets/icons/prayer.png';
import sad from '../../assets/icons/sad.png';
import wisdom from '../../assets/icons/wisdom.png';
import calm from '../../assets/icons/calm.png';


const ViewCategory = () => {
    const { id } = useParams();
    const [responseData, setResponseData] = useState(null);

    const handleGet = async () => {
        try {
        const response = await getData(`fetchVersesInCategory&categoryID=${id}`);
            setResponseData(response);
        } catch (error) {
            console.error('Error in handleGet:', error);
        }
    };

    useEffect(() => {
        handleGet();
    }, []);

    const getIconUrl = (iconName) => {
        switch (iconName) {
            case 'anger':
                return anger;
            case 'anxiety':
                return anxiety;
            case 'cooperation':
                return cooperation;
            case 'finger':
                return finger;
            case 'love':
                return love;
            case 'pray':
                return pray;
            case 'prayer':
                return prayer;
            case 'sad':
                return sad;
            case 'wisdom':
                return wisdom;
            case 'calm':
                return calm;
            default:
                return '';
        }
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };
    
    return(
        <Grid container direction={{ xs: "row", md: "column" }} alignItems="center" justifyContent="center" className={styles.mainGrid}>
            <Grid item sx={{ width: { xs: "100%", md: "37%" }, display:"flex", flexDirection:"row"}}  >
                <Box sx={{width: "15%", display: "flex"}}>
                    <IconButton component={Link} to="/categories">
                        <ChevronLeftOutlinedIcon sx={{ fontSize: "40px" }} />
                    </IconButton>
                </Box>
                <Box sx={{width: "85%", display: "flex",  alignItems: "center", }}>
                    {responseData && (
                        <>
                            <Avatar alt="text" src={getIconUrl(responseData.icon)} sx={{marginRight: "5px"}}/>
                            <Typography variant='h5' component="h2">{responseData.category}</Typography>
                        </>
                    )}
                </Box>
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center" sx={{minHeight: "70vh"}}>
                    {responseData && responseData.verseData && (
                        <Grid item>
                                {Object.values(responseData.verseData).map((verse, index) => {
                                    let linkHref = `/viewverse/${id}/${verse.verseID}`;
                                    return(
                                        <Link to={linkHref} className={styles.navLinks}>
                                        <Grid 
                                            sx={{
                                                borderLeft: `3px solid ${verse.bookGroupColor}`,
                                                textAlign: "left",
                                                paddingLeft: "20px",
                                                margin: "15px"
                                            }} 
                                        >
                                            <Typography variant="body1" component="h2">{`${verse.book.toUpperCase()} ${verse.chapter}:${verse.verse}`}</Typography>
                                            <Typography variant="body1">{truncateText(verse.verse_text, 65)} </Typography>
                                        </Grid>
                                        </Link>
                                    );
                                   
                                })}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ViewCategory;