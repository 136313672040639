import { Grid, Typography, Button, TextField } from "@mui/material";

import { useFormik } from 'formik';
import * as yup from 'yup';

import CustomSelect from "../Select";

import { postData } from "../../../services/api";

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    category: yup
      .string('Select a category')
      .required('Category is required'),
      message: yup
      .string('Please fill in a message')
      .required('Your Message is required')
  });

const CustomForm = (props) => {
    const formik = useFormik({
        initialValues: {
          email: '',
          category: '',
          message: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            try {
                let data = {
                    method: "saveFeedbackForm",
                    formValues: values
                }
                console.log(JSON.stringify(data, null, 2));
                const success = postData(JSON.stringify(data, null, 2));
                console.log(success);
                if (success) {
                    alert("Thank you, we appreciate your feedback.");
                    setTimeout(() => {
                        window.location.reload();
                    }, "1000");
                } else {
                    console.error('Error posting form: API response not "success"');
                }
            } catch (error) {
                console.error('Error posting form:', error);
            }
        },
      });
    
      return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                margin: "10px 0px"
              }}
            />
            <CustomSelect 
                id="category"
                name="category"
                label="Category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
                sx={{
                  margin: "10px 0px"
                }}
            />
            <TextField
                id="message"
                name="message"
                label="Your Message"
                multiline
                rows={8}
                fullWidth
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                sx={{
                    margin: "10px 0px"
                }}
            />
            <Button color="info" variant="outlined" fullWidth type="submit">
              Send Feedback
            </Button>
          </form>
        </Grid>
      );
}

export default CustomForm;