import * as React from 'react';
import { Grid, List, ListItem, ListItemAvatar, Avatar, Divider, ListItemButton, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { getData } from '../../services/api';

import anger from '../../assets/icons/anger.png';
import anxiety from '../../assets/icons/anxiety.png';
import cooperation from '../../assets/icons/cooperation.png';
import finger from '../../assets/icons/finger.png';
import love from '../../assets/icons/love.png';
import pray from '../../assets/icons/pray.png';
import prayer from '../../assets/icons/prayer.png';
import sad from '../../assets/icons/sad.png';
import wisdom from '../../assets/icons/wisdom.png';
import calm from '../../assets/icons/calm.png';

import { Link } from 'react-router-dom';

import styles from './categories.module.css';

const iconMap = {
    anger,
    anxiety,
    cooperation,
    finger,
    love,
    pray,
    prayer,
    sad,
    wisdom,
    calm
};


const Categories = () => {
  const [responseData, setResponseData] = useState(null);

  const handleGet = async () => {
    try {
      const response = await getData('fetchVerseCategories');
      setResponseData(response);
    } catch (error) {
      console.error('Error in handleGet:', error);
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  return (
    <Grid container direction={{ xs: "row", md: "column" }} justifyContent="center" alignItems="center" className={styles.mainGrid} spacing={0}>
        <Grid item xs={12} md={12}>
            <Typography variant='h5' component="h2">Categories</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {responseData && responseData.map((item, index) => {
                    let avatarImage = iconMap[item.icon];
                    let linkHref = `/viewcategory/${item.id}`;
                    return (
                        <React.Fragment key={index}>
                            <ListItem>
                                <Link to={linkHref} className={styles.navLinks}>
                                    <ListItemButton>    
                                        <ListItemAvatar>
                                            <Avatar alt={item.name} src={avatarImage} />
                                        </ListItemAvatar>
                                        <Typography variant='h6'>{item.name}</Typography>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    );
                })}
            </List>
        </Grid>
    </Grid>
  );
};

export default Categories;
