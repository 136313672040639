import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a context
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const loggedInStatus = sessionStorage.getItem("loggedIn");
    const storedUserData = sessionStorage.getItem("userData");

    if (loggedInStatus === 'true' && storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (parsedUserData.token) {
        setIsLoggedIn(true);
        setUserData(parsedUserData);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, userData, setIsLoggedIn, setUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
