import VerseOfTheDay from "../../components/VerseOfTheDay";
import VersesOfTheMonth from "../../components/VersesOfTheMonth";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

import styles from './home.module.css'

const Home = () => {   
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.mainGrid}>
            <Grid item> 
              <Box className={styles.vodCard}>
                <VerseOfTheDay />
              </Box>
              <Box className={styles.vodCard}>
                <VersesOfTheMonth />
              </Box>
            </Grid>
        </Grid>
    );
}

export default Home;
