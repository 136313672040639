import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { useState, useEffect } from 'react';

import { getData } from '../../services/api';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import styles from './modal.module.css';

import CustomButton from '../Custom_Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = useState(false);
  const [fullChapter, setFullChapter] = useState();

  const getFullChapter = async () => {
    try {
      const response = await getData(`getFullChapter&chapter=${props.chapterID}&book=${props.bookID}`);
      setFullChapter(response);
    } catch (error) {
      console.error('Error in handleGet:', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    getFullChapter(); 
  };

  const handleClose = () => setOpen(false);

   const isInRange = (range, key) => {
    const [start, end] = range.split('-').map(Number);
    const keyNum = Number(key);
    return keyNum >= start && keyNum <= end;
  };

  const shouldHighlight = (verseKey) => {
    if (props.verseID.includes('-')) {
      return isInRange(props.verseID, verseKey);
    }
    return props.verseID === verseKey;
  };

  return (
    <div>
      <CustomButton text={props.buttonText} variant="contained" onClick={() => handleOpen()}/>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.modalContainer}>
            {fullChapter && (
                <Box >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{textAlign: "center"}}>
                        {fullChapter.book}
                    </Typography>
                    <Typography variant="h6" component="h2" sx={{textAlign: "center"}}>
                      Chapter {fullChapter.chapter} ({fullChapter.accronymn})
                    </Typography>
                    <Box className={styles.modalContent}>
                      {Object.keys(fullChapter.verses).map((verseKey) => (
                          <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} key={verseKey}>
                              <strong>{verseKey}. </strong> 
                              <span className={shouldHighlight(verseKey) ? styles.highlightedText : ''}>
                                {fullChapter.verses[verseKey]}
                              </span>
                            </Typography>
                            <br />
                          </>
                        ))}
                    </Box>
                </Box>
            )}
          <Box sx={{textAlign:"right"}}>
              <CustomButton text="Close" variant="contained" icon={<CloseOutlinedIcon/>} icon_position="start" onClick={() => handleClose()}/>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}