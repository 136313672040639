import { Grid, Typography, List, ListItem } from "@mui/material";

import styles from "./abount.module.css"

import HeroImage2 from "../../assets/images/about_image_2.jpg"


const About = () => {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.mainGrid}>
            <Grid item>
                <Grid className={styles.imageGrid} 
                    sx={{
                        backgroundImage: `url(${HeroImage2})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "local",
                        backgroundSize: "2000px",
                        backgroundPosition: "center -400px"
                    }}
                ></Grid>
                <Typography variant="h2" className={styles.heroHeading}>About The Idea</Typography>

                <Grid item >
                    <Typography variant="body1" sx={{fontSize: {xs:"18px", md:"24px"}}}>
                        "I am the Lord, and there is no other; apart from me there is no God.
                        <br /> 
                        I will strengthen you, though you have not acknowledged me,"
                        <br />
                        Isaiah 45:5
                    </Typography>
                </Grid>

                <Grid container p={5} direction="row" justifyContent="center" alignItems="center">
                    <Grid item  className={styles.contentGrid}>
                        <Typography variant="h4">Welcome</Typography>
                        <Typography variant="body1">
                            Glimpse Of The Gospel is a unique web app designed to help both Christians and seekers explore and connect with the profound messages of the Bible. 
                            Our mission is to provide a platform where users can delve deeper into the Scriptures, 
                            discover their favorite verses, and better understand Jesus's teachings and God's will.
                        </Typography>
                    </Grid>
                    <Grid item sx={{ width: {xs: "100%", md: "50%"}}} className={styles.secondGrid}>
                        <Typography variant="h4">Our Purpose</Typography>
                        <Typography variant="body1">
                            At Glimpse Of The Gospel, we believe in the transformative power of God's Word. 
                            Our goal is to make the Bible more accessible and engaging for everyone. 
                            Whether you are a lifelong Christian or someone curious about the faith, our app offers a comprehensive and interactive way to explore the Bible.
                        </Typography>
                    </Grid>
                    <Grid item sx={{ width: {xs: "100%", md: "50%"}}} className={styles.thirdGrid}>
                        <Typography variant="h4">Our Vision</Typography>
                        <Typography variant="body1">
                            We envision a world where people are inspired and transformed by the teachings of the Bible. 
                            By providing a space for interactive exploration and community engagement, 
                            we aim to foster a deeper connection with God's Word and a greater understanding of His message.
                        </Typography>
                    </Grid>
                </Grid>
                
                <Grid container p={5} direction="row" justifyContent="center" alignItems="center">
                    <Grid item sx={{ width: {xs: "100%", md: "50%"}}}>
                        <Typography variant="h4">Key Features</Typography>
                        <List>
                            <ListItem>
                                <Typography variant="body1">
                                    <strong>Rate Your Favorite Verses:</strong>&nbsp;
                                    Share your insights and see how others rate their favorite Bible passages. Our rating system allows you to discover which verses resonate most with the community.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body1">
                                    <strong>Explore Bible Statistics:</strong>&nbsp; 
                                    Gain a deeper understanding of the Bible through detailed statistics. 
                                    Learn about the number of chapters, books, verses, characters, and more. Our app provides a comprehensive overview of the Bible's structure and content.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body1">
                                    <strong>Deepen Your Faith:</strong>&nbsp;
                                    Use Glimpse Of The Gospel as a tool to better understand Jesus's message and God's will. 
                                    Our platform encourages thoughtful reflection and meaningful engagement with the Scriptures.
                                </Typography>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default About;