import { Typography, Grid, IconButton, Avatar, Box, Tabs, Tab } from '@mui/material';
import PropTypes from 'prop-types';

import { useParams, Link } from "react-router-dom";

import { getData } from '../../services/api';

import { useState, useEffect } from 'react';

import styles from "./myverses.module.css"

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const MyVerses = () => {
    const { userID } = useParams();
    const [responseData, setResponseData] = useState(null);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleGet = async () => {
        try {
            const response = await getData(`fetchUsersVerses&userID=${userID}`);
            setResponseData(response);
        } catch (error) {
            console.error('Error in handleGet:', error);
        }
    };

    useEffect(() => {
        handleGet();
    }, []);


    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };
    
    return(
        <Grid container direction="column" alignItems="center"  className={styles.mainGrid}>
            <Grid item>
                <Typography variant='h5' component="h2">My Verses</Typography>
            </Grid>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Liked" {...a11yProps(0)} />
                        <Tab label="Saved" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Grid container direction="column" alignItems="center" sx={{minHeight: "70vh"}}>
                        {responseData && responseData.liked.verseData && (
                            <Grid item>
                                    {Object.values(responseData.liked.verseData).map((verse, index) => {
                                        let linkHref = `/viewverse/0/${verse.verseID}`;
                                        return(
                                            <Link  to={linkHref} className={styles.navLinks}>
                                                <Grid 
                                                    sx={{
                                                        borderLeft: `3px solid ${verse.bookGroupColor}`,
                                                        textAlign: "left",
                                                        paddingLeft: "20px",
                                                        margin: "15px"
                                                    }} 
                                                >
                                                    <Typography variant="body1" component="h2">{`${verse.book.toUpperCase()} ${verse.chapter}:${verse.verse}`}</Typography>
                                                    <Typography variant="body1">{truncateText(verse.verse_text, 65)} </Typography>
                                                </Grid>
                                            </Link>
                                        );
                                    
                                    })}
                            </Grid>
                        )}
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Grid container direction="column" alignItems="center" sx={{minHeight: "70vh"}}>
                        {responseData && responseData.saved.verseData && (
                            <Grid item>
                                    {Object.values(responseData.saved.verseData).map((verse, index) => {
                                        let linkHref = `/viewverse/0/${verse.verseID}`;
                                        return(
                                            <Link  to={linkHref} className={styles.navLinks}>
                                                <Grid 
                                                    sx={{
                                                        borderLeft: `3px solid ${verse.bookGroupColor}`,
                                                        textAlign: "left",
                                                        paddingLeft: "20px",
                                                        margin: "15px"
                                                    }} 
                                                >
                                                    <Typography variant="body1" component="h2">{`${verse.book.toUpperCase()} ${verse.chapter}:${verse.verse}`}</Typography>
                                                    <Typography variant="body1">{truncateText(verse.verse_text, 65)} </Typography>
                                                </Grid>
                                            </Link>
                                        );
                                    
                                    })}
                            </Grid>
                        )}
                    </Grid>
                </CustomTabPanel>
            
            <Grid item>
                
            </Grid>
        </Grid>
    );
}

export default MyVerses;