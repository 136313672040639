import { Grid, Typography, List, ListItem } from "@mui/material";

import styles from "./pp.module.css"

const PrivacyPolicy = () => {
    return(
        <Grid container className={styles.mainGrid}>
            <Grid item className={styles.textFormatting}> 
                <Typography variant="h4">Introduction</Typography>
                <Typography variant="body1">
                    Welcome to Glimpse Of The Gospel. We value your privacy and are committed to protecting your personal information. 
                    This Privacy Policy explains how we collect, use, and share your information when you use our web app.
                </Typography>

                <Typography variant="h4">Information We Collect</Typography>

                <Typography variant="h5">Personal Information</Typography>
                <Typography variant="body1">
                    We may collect the following personal information when you use our app:
                </Typography>
                <List>
                    <ListItem>
                        <Typography>
                            <strong>Contact Information: </strong>Such as your name, email address, and any other contact details you provide.
                        </Typography>
                    </ListItem>
                    <ListItem>
                    <Typography>
                            <strong>Account Information: </strong>Information related to your account, including username and password.
                        </Typography>
                    </ListItem>
                    <ListItem>
                    <Typography>
                            <strong>Feedback and Ratings: </strong>Any feedback or ratings you provide on Bible verses.
                        </Typography>
                    </ListItem>
                </List>

                <Typography variant="h5">Usage Data</Typography>
                <Typography variant="body1">
                    We collect information about how you use our app, including:
                </Typography>
                <List>
                    <ListItem>
                        <Typography>
                            <strong>Device Information: </strong>Details about the device you use to access our app, such as IP address, browser type, and operating system.
                        </Typography>
                    </ListItem>
                    <ListItem>
                    <Typography>
                            <strong>Usage Information: </strong>Information about your interactions with the app, such as pages visited, features used, and time spent on the app.
                        </Typography>
                    </ListItem>
                </List>

                <Typography variant="h4">How We Use Your Information</Typography>
                <Typography variant="body1">
                    We use your information to:
                </Typography>
                <List>
                    <ListItem>
                        <Typography>
                            <strong>Provide and Improve Our Services: </strong>To operate and maintain our app, personalize your experience, and improve our services.
                        </Typography>
                    </ListItem>
                    <ListItem>
                    <Typography>
                            <strong>Communicate with You: </strong>To respond to your inquiries, send you updates, and provide information about the app.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            <strong>Analyze Usage: </strong>To understand how users interact with our app and to develop new features and improvements.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            <strong>Ensure Security: </strong>To protect the security and integrity of our app and user data.
                        </Typography>
                    </ListItem>
                </List>

                <Typography variant="h4">How We Share Your Information</Typography>
                <Typography variant="body1">
                    We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
                </Typography>
                <List>
                    <ListItem>
                        <Typography>
                            <strong>Service Providers: </strong>We may share information with third-party service providers who help us operate and maintain our app.
                        </Typography>
                    </ListItem>
                    <ListItem>
                    <Typography>
                            <strong>Legal Requirements: </strong>We may disclose your information if required by law or in response to legal processes, such as court orders or subpoenas.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            <strong>Protection of Rights: </strong>We may share information to protect the rights, property, or safety of Glimpse Of The Gospel, our users, or others.
                        </Typography>
                    </ListItem>
                </List>

                <Typography variant="h4">Data Security</Typography>
                <Typography variant="body1">
                    We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. 
                    However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
                </Typography>

                <Typography variant="h4">Cookies and Tracking Technologies</Typography>
                <Typography variant="body1">
                    We use cookies and similar tracking technologies to collect and use your information as described in this Privacy Policy. 
                    You can manage your cookie preferences through your browser settings.
                </Typography>

                <Typography variant="h4">Changes to This Privacy Policy</Typography>
                <Typography variant="body1">
                    We may update this Privacy Policy from time to time. 
                    Any changes will be posted on this page, and the effective date will be updated accordingly. 
                    We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default PrivacyPolicy;