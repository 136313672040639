import { getData } from "../../services/api";
import { useState, useEffect } from "react";
import BasicModal from "../Modal";

import { Grid, Typography, Box, IconButton } from "@mui/material";

import ShareIconButton from "../ShareIcon";
import LikeButton from "../LikeButton";

import styles from './vod.module.css';

import CardComponent from "../CardComponent";

const VerseOfTheDay = () => {   
    const [responseData, setResponseData] = useState(null);

    const handleGet = async () => {
        try {
            const response = await getData("fetchVerseOfTheDay");
            setResponseData(response);
        } catch (error) {
            console.error('Error in handleGet:', error);
        }
      };

    useEffect(() => {
        handleGet();
    }, []);

    const cardContent = () => (
        <>
            <Box className={styles.heroText}>
                <Typography variant="h4">Verse Of The Day</Typography>
            </Box>
            <Box>
                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                    {responseData.book} {responseData.chapter}: {responseData.verse} ({responseData.accronymn})
                </Typography>
                <Typography sx={{ mb: 1.5 }} variant="h5" color="text.primary" className={styles.mainText}>
                    {responseData.verse_text}
                </Typography>
            </Box>
        </>
    );

    const cardActions = () => (
        <>
            <Box sx={{ textAlign: "left", width: "50%" }}>
                <BasicModal buttonText="Full Chapter" chapterID={responseData.chapter} bookID={responseData.bookID} verseID={responseData.verse} />
            </Box>
            <Box sx={{ textAlign: "right", width: "50%" }}>
                <LikeButton likes={responseData.likes} id={responseData.id} disabled={true} />
                <IconButton aria-label="share" size="small" sx={{ color: "blue !important" }}>
                    <ShareIconButton
                        verseID={responseData.id}
                        verseBook={responseData.book}
                        verseChapter={responseData.chapter}
                        verseNumber={responseData.verse}
                        verseAccronymn={responseData.accronymn}
                        url=""
                        text="See the verse of the day."
                    />
                </IconButton>
            </Box>
        </>
    );

    return (
        <Grid>
            {responseData && (
                <Grid>
                    <CardComponent 
                        cardContent={cardContent}
                        cardActions={cardActions}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default VerseOfTheDay;