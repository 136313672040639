import { Grid, Typography, Button, TextField, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material"
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from "./register.module.css"

import Logo from "../../assets/images/logo_new.png"

import { Link } from "react-router-dom";
import { useState } from "react";

import { postData } from "../../services/api";

const validationSchema = yup.object({
    username: yup
        .string('Enter your username')
        .required('Username is required'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    confirmpassword: yup
        .string('Confirm your password')
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const checkAvailability = async (field, value, setAvailability) => {
    let data = {
        method: `check${field.charAt(0).toUpperCase() + field.slice(1)}Exists`,
        data: { [field]: value },
    };
    const response = await postData(data);
    // console.log(`Checking availability for ${field}:`, response);  // Debug log
    setAvailability(response);
};


const RegisterPage = () => {
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
    const [isEmailAvailable, setIsEmailAvailable] = useState(true);
    const [open, setOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
            confirmpassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let data = {
                method: "registerNewUser",
                data: values,
            }
            const response =  postData(data);
            if (response) {
                setOpen(true);
            } else {
                console.error('Error updating likes: API response not "success"');
            }

            // alert(JSON.stringify(values, null, 2));
        },
      });

      const handleClose = () => {
        setOpen(false);
      };
    

      const debouncedCheckAvailability = debounce(checkAvailability, 1000);

      const renderTextField = (id, label, type = "text") => (
            <TextField
                fullWidth
                id={id}
                name={id}
                label={label}
                type={type}
                value={formik.values[id]}
                onChange={(e) => {
                    formik.handleChange(e);
                    if (id === 'username') {
                        setIsUsernameAvailable(false);
                        debouncedCheckAvailability('username', e.target.value, setIsUsernameAvailable);
                    } else if (id === 'email') {
                        setIsEmailAvailable(true);
                        debouncedCheckAvailability('email', e.target.value, setIsEmailAvailable);
                    }
                }}
                onBlur={(e) => {
                    formik.handleBlur(e);
                    if (id === 'username') {
                        debouncedCheckAvailability('username', e.target.value, setIsUsernameAvailable);
                    } else if (id === 'email') {
                        debouncedCheckAvailability('email', e.target.value, setIsEmailAvailable);
                    }
                }}
                error={formik.touched[id] && (Boolean(formik.errors[id]) || (id === 'username' && !isUsernameAvailable) || (id === 'email' && !isEmailAvailable))}
                helperText={
                    formik.touched[id] && formik.errors[id]
                        ? formik.errors[id]
                        : id === 'username' && !isUsernameAvailable
                        ? 'Username is already taken'
                        : id === 'email' && !isEmailAvailable
                        ? 'Email is already in use'
                        : ''
                }
                className={styles.textFields}
                margin="normal"
           />
        );
    
      return (
        <Grid direction="row" justifyContent="center" alignItems="center" className={styles.mainGrid}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Account Created"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    We are so happy to see you join our community. May God bless you on all your adventures. Please head over to the login screen to log into your account.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} autoFocus component={Link} to="/login">
                    Login
                </Button>
                </DialogActions>
            </Dialog>
            <Grid item>
                <img src={Logo} alt="logo" className={styles.logo}/>
            </Grid>
            <Grid item>
                <Typography variant="h5" component="h2">Create A New Account</Typography>
            </Grid>
            <Grid item p={{xs:3}}>
                <form onSubmit={formik.handleSubmit}>
                    {renderTextField("email", "Email", "email")}
                    {renderTextField("username", "Username")}
                    {renderTextField("password", "Password", "password")}
                    {renderTextField("confirmpassword", "Confirm Password", "password")}
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        className={styles.button_filled}
                        sx={{marginTop: "20px"}}
                        disabled={!isUsernameAvailable || !isEmailAvailable}
                    >
                        Register
                    </Button>
                </form>
            </Grid>
            <Grid item>
                <Typography variant="body1" >
                    Allready have an account?&nbsp; 
                    <br />
                    <Link className={styles.linkText} to="/login">Login</Link>
                </Typography>
            </Grid>
        </Grid>
      );
}

export default RegisterPage;