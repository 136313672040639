import { Grid, Button, Typography } from "@mui/material";
import styles from "./button.module.css"

import { Link } from "react-router-dom";

const CustomButton = (props) =>{

    const buttonClass = ` ${
        props.variant === "contained" ? styles.button_filled : styles.button_outlined
    }`;

    const iconProps = props.icon_position === 'start' ? { startIcon: props.icon } : { endIcon: props.icon };
    const onClick = props.onClick ? props.onClick : undefined;

    return(
        <Grid>
            {props.to ? (
                <Button
                    variant={props.variant}
                    className={buttonClass}
                    component={Link} // Use Link as the component
                    to={props.to} // Specify the target route
                    {...iconProps}
                    sx={props.sx}
                >
                    {props.text}
                </Button>
            ) : (
                <Button
                    variant={props.variant}
                    className={buttonClass}
                    onClick={onClick} // Use the onClick handler if not a link
                    {...iconProps}
                    sx={props.sx}
                >
                    {props.text}
                </Button>
            )}
        </Grid>
    );
}

export default CustomButton;