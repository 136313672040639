import { IconButton, Snackbar } from "@mui/material";
import { postData } from "../../services/api";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import { useState } from "react";

import { useAuth } from '../../context/AuthContext';

const LikeButton = (props) => {
    const [likes, setLikes] = useState(props.likes);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { isLoggedIn, userData } = useAuth();
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    };

    const handleClick = async (verseID) => {
        try {
            setIsButtonDisabled(true);
            let data = {
                method: "updateVerseRatings",
                verse_id: verseID
            }

            if(isLoggedIn){
                data.userID = userData.userID;
            }

            const success = await postData(data);
            if (success) {
                setLikes(parseInt(likes) + 1);
                if(isLoggedIn){
                    setOpen(true);
                }
            } else {
                console.error('Error updating likes: API response not "success"');
            }
        } catch (error) {
            console.error('Error updating likes:', error);
        }
    }

    return(
        <>
            {props.disabled ? (
                <>
                    <IconButton aria-label="like" size="small" m={0} sx={{color: "red !important"}} >
                        <FavoriteIcon />
                    </IconButton>
                    <span sx={{color: "grey !important", margiRight: "10px"}}>{props.likes}</span>
                </>
            ) : (
                <>
                    <IconButton aria-label="like" size="small" m={0} sx={{color: "red !important"}} onClick={() => handleClick(props.id)} disabled={isButtonDisabled}>
                        {likes > props.likes ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />}
                    </IconButton>
                    <span sx={{color: "grey !important", margiRight: "10px"}}>{likes}</span>
                </>
            )}
            <Snackbar 
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Verse added to liked verses."
                sx={{
                    marginBottom: "80px"
                }}
            />
        </>
    );
}

export default LikeButton;