import { Grid, IconButton, Tooltip, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Button } from '@mui/material';

import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

import { postData, getData } from "../../services/api";

import { useAuth } from '../../context/AuthContext';

import { useState, useEffect } from 'react';

const BookmarkButton = (props) => {
    const [isSaved, setIsSaved] = useState(false);
    const { isLoggedIn, userData } = useAuth();
    const [open, setOpen] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const isVerseSaved = async () => {
        try {
            const response = await getData(`isVerseSaved&verseID=${props.id}&userID=${userData.userID}`);

            return (response === true) ? true : false;
        } catch (error) {
            console.error('Error in isVerseSaved:', error);
            return false;
        }
    };

    useEffect(() => {
        const checkIfVerseIsSaved = async () => {
            if (isLoggedIn) {
                const saved = await isVerseSaved();
                setIsSaved(saved);
                // setIsButtonDisabled(saved);
            }
        };
        checkIfVerseIsSaved();
    }, [isLoggedIn, userData, props.id]);
    
    const handleClick = async () => {
        if(!isLoggedIn){
            setOpen(true);
        }else if(isSaved){
            // setIsButtonDisabled(true);
            try {
                let data = {
                    method: "unBookmarkVerse",
                    verse_id: props.id,
                    userID: userData.userID
                }
              
                const response = await postData(data);
                if (response === "success") {   
                    setIsSaved(false);
                } else {
                    console.error('Error un-bookmarking verse: API response not "success"');
                }
            } catch (error) {
                console.error('Error un-bookmarking verse:', error);
            }
        }else{
            try {
                let data = {
                    method: "bookmarkVerse",
                    verse_id: props.id,
                    userID: userData.userID
                }

                const response = await postData(data);

                if (response === "success") {
                    setIsSaved(true);
                    setIsButtonDisabled(true);
                } else {
                    console.error('Error bookmarking verse: API response not "success"');
                }
            } catch (error) {
                console.error('Error bookmarking verse:', error);
            }
        }
    }


    return(
        <Grid sx={props.sx}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Oops"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please login in to your account or register a new account to bookmark this verse.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
            <IconButton onClick={handleClick}>
                <svg width={0} height={0}>
                    <defs>
                    <linearGradient id="gradientColors" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#9BF1F9" />
                        <stop offset="100%" stopColor="#1885E9" />
                    </linearGradient>
                    </defs>
                </svg>
                {isSaved ? <BookmarkAddedIcon sx={{ fill: { xs: "url(#gradientColors)", md: "black" }, fontSize: "30px" }}/> : <BookmarkBorderOutlinedIcon sx={{ fill: { xs: "url(#gradientColors)", md: "black" }, fontSize: "30px" }}/>}
            </IconButton>
        </Grid>
    );
}

export default BookmarkButton;