import { Grid, Typography } from "@mui/material";
import Verse from "../../components/Verse";

import { getData } from "../../services/api";

import { useState, useEffect } from "react";

import styles from './rv.module.css'

const RateVerses = () => {
    const [responseData, setResponseData] = useState(null);

    const handleGet = async () => {
        try {
          const response = await getData("fetchVerses");
          setResponseData(response);
        } catch (error) {
          console.error('Error in handleGet:', error);
        }
      };

    useEffect(() => {
        handleGet();
    }, []);

    return (
        <Grid className={styles.mainGrid}>
            <Typography variant="h4">Choose Your Favorite Verse</Typography>
            {responseData && responseData.map((item, index) => (
                <Verse key={index} verse_data={item}/>
            ))}
        </Grid>
       
    );
}

export default RateVerses;