import { Grid, Box, Typography, IconButton } from "@mui/material";

import { postData } from "../../services/api";
import { useState } from "react";

import LikeButton from "../LikeButton";

import ShareIconButton from "../ShareIcon";

import BasicModal from "../Modal";

import CardComponent from "../CardComponent";

const Verse = (props) => {
    const [likes, setLikes] = useState(props.verse_data.likes);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    
    const handleClick = async (verseID) => {
        try {
            setIsButtonDisabled(true);
            let data = {
                method: "updateVerseRatings",
                verse_id: verseID
            }
            const success = await postData(data);
            if (success) {
                setLikes(parseInt(likes) + 1);
            } else {
                console.error('Error updating likes: API response not "success"');
            }
        } catch (error) {
            console.error('Error updating likes:', error);
        }
    }

    const cardContent = () => (
        <>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                {props.verse_data.book} {props.verse_data.chapter}: {props.verse_data.verse} ({props.verse_data.accronymn})
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="h5" color="text.primary">
                {props.verse_data.verse_text}
            </Typography>
        </>
    );

    const cardActions = () => (
        <>
            <Box sx={{textAlign: "left", width:"50%"}}>
                <BasicModal buttonText="Full Chapter" chapterID={props.verse_data.chapter} bookID={props.verse_data.bookID} verseID={props.verse_data.verse}/>
            </Box>
            <Box sx={{textAlign: "right", width:"50%"}}>
                <LikeButton likes={likes} id={props.verse_data.id} onClick={() => handleClick(props.verse_data.id)} disabled={isButtonDisabled} />
                <IconButton aria-label="share" size="small" sx={{color: "blue !important"}}>
                    <ShareIconButton 
                        verseID={props.verse_data.id} 
                        verseBook={props.verse_data.book} 
                        verseChapter={props.verse_data.chapter} 
                        verseNumber={props.verse_data.verse} 
                        verseAccronymn={props.verse_data.accronymn}
                        url="rateverses"
                        text="Choose your favorite verse."
                    />
                </IconButton>
            </Box>
        </>
    );

    return(
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
                <CardComponent 
                    cardContent={cardContent}
                    cardActions={cardActions}
                />
            </Grid>
        </Grid>
    );
}

export default Verse;